@charset "UTF-8";
@import './assets/scss/colors.scss';
@import './assets/scss/fonts.scss';

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $color-background-body;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

.back-to-top {
  margin: 0 !important;
  bottom: 30px !important;
  right: 30px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
  border-left-style: solid !important;
}

.border-radius-6 {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border-radius: 6px !important;
}

.bg-dark {
  background-color: $color-dark;
}

.bg-dark-light {
  background-color: $color-dark-light;
}

.bg-grey {
  background-color: $color-grey;
}

.bg-grey-light {
  background-color: $color-grey-light;
}

.bg-success {
  background-color: $color-success;
}

.bg-warning {
  background-color: $color-warning;
}

.bg-danger {
  background-color: $color-danger;
}

.bg-primary {
  background-color: $color-primary;
}

.link-dark {
  color: $color-dark;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.link-primary {
  color: $color-primary;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.text-dark {
  color: $color-dark;
}

.text-dark-light {
  color: $color-dark-light;
}

.text-grey {
  color: $color-grey;
}

.text-grey-light {
  color: $color-grey-light;
}

.text-success {
  color: $color-success;
}

.text-warning {
  color: $color-warning;
}

.text-danger {
  color: $color-danger;
}

.text-primary {
  color: $color-primary;
}

.r-3 {
  right: 1rem !important;
}

.swal2-container {
  z-index: 1360 !important;
}

.scrolling {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  -o-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
  height: calc(100vh - 10%);
  z-index: 4;
}
