@charset "UTF-8";
@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/fonts.scss';

.wrapper {
    display: flex;
    position: relative;

    .sidebar {
        width: 230px;
        margin: 0;
        padding: 0;
        height: 100vh;
        overflow-Y: auto;
        overflow-x: hidden;
        webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: translateX(-240px);
        -moz-transform: translateX(-240px);
        -ms-transform: translateX(-240px);
        -o-transform: translateX(-240px);
        transform: translateX(-240px);
        -webkit-box-shadow: $box-shadow-right;
        -moz-box-shadow: $box-shadow-right;
        -ms-box-shadow: $box-shadow-right;
        -o-box-shadow: $box-shadow-right;
        box-shadow: $box-shadow-right;
        z-index: 1300 !important;
    }

    .sidebar.sidebar-open {
        webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }

    .mainContent {
        margin-left: 230px;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        padding: 0;
        width: 100%;

        .content {
            padding: 80px 20px 20px 20px;
            color: #717171;
            line-height: 20px;
            width: 100%;
        }
    }

    .mainContent.mainContent-open {
        margin-left: 0px;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }
}
