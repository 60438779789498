@charset "UTF-8";
@import '../../../../assets/scss/colors';
@import '../../../../assets/scss/fonts';

.menu-responsive {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    -webkit-transform: translateX(230px);
    -moz-transform: translateX(230px);
    -ms-transform: translateX(230px);
    -o-transform: translateX(230px);
    transform: translateX(230px);
}

.menu-responsive.menu-responsive-open {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}
