$color-background-body: #F9F9F9 !important;
$color-white: #FFFFFF !important;
$color-transparent: transparent !important;
$color-dark: #1B1B1B !important;
$color-dark-light: #6B6B6B !important;
$color-grey: #CCD1D1 !important;
$color-grey-light: #EAECEE !important;
$color-success: #28B463 !important;
$color-warning: #D4AC0D !important;
$color-danger: #CB4335 !important;
$color-primary: #3A50D3 !important;

$box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
$box-shadow-right: 0px 5px 5px 5px rgba(0, 0, 0, 0.2);
$box-shadow-none: none !important;
